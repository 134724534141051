<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CardGabageBankDeposit v-if="userData.std_ordinal" />
      </CCol>
      <CCol lg="6">
        <CardLitterBankWithdraw v-if="userData.std_ordinal" />
      </CCol>
    </CRow>

    <TblDepositLogByDate />
  </div>
</template>

<script>
import TblScreeningLogBySchool from "./components/TblScreeningLogBySchool";
import TblDepositLogByDate from "./components/TblDepositLogByDate";
import SltAmphur from "./components/SltAmphur";
import CardGabageBankDeposit from "./components/CardGabageBankDeposit";
import CardLitterBankWithdraw from "./components/CardLitterBankWithdraw";

export default {
  name: "Dashboard",
  components: {
    TblScreeningLogBySchool,
    TblDepositLogByDate,
    SltAmphur,
    CardGabageBankDeposit,
    CardLitterBankWithdraw
  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
    };
  },
  methods: {},
  mounted() {},
};
</script>
