<template>
  <CCard v-if="userData.std_auth.search('littleBank') > 0">
    {{ $store.state.selectedStudent }}
    <form
      id="formCreateWithdraw"
      v-on:submit.prevent="createWithdraw"
      method="POST"
    >
      <CCardHeader class="bg-black"
        ><strong class="text-white">บันทึกการถอนเงินฝาก</strong></CCardHeader
      >
      <CCardBody>
        <CRow>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสผู้ทำรายการ:
              </label>
              <input
                type="number"
                name="userId"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                readonly
                v-model="userData.stud_id"
              />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสโรงเรียน:
              </label>
              <input
                type="number"
                name="schoolCode"
                class="form-control"
                readonly
                v-model="userData.std_school"
              /></div
          ></CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสนักเรียนผู้ถอนเงิน:
              </label>
              <input
                type="text"
                name="customerId"
                class="form-control"
                v-model="depositer"
                required
                @input="checkIsFilled"
              /></div
          ></CCol>
          <CCol lg="12" v-if="userData">
            <div v-if="depositerDetail" class="alert alert-warning">
              <div>
                <strong>ชื่อ นามสกุล:</strong> {{ depositerDetail.prefix_name
                }}{{ depositerDetail.std_name }}
                {{ depositerDetail.std_surname }}
              </div>
              <div>
                <strong>ห้อง:</strong> {{ depositerDetail.level_abv_name }}/{{
                  depositerDetail.std_class
                }}
              </div>
              <div class="text-primary">
                <strong>ฝาก:</strong> {{ sumDeposit.toLocaleString() }} บาท
                <br />
              </div>
              <div class="text-danger">
                <strong>ถอน:</strong> {{ sumWithdraw.toLocaleString() }} บาท
                <br />
              </div>
              <div>
                <strong>คงเหลือ:</strong>
                {{ (sumDeposit + sumWithdraw).toLocaleString() }} บาท <br />
              </div>
            </div>
            <div v-else>--โปรดกรอกเลขประจำตัวนักเรียนให้ถูกต้อง--</div>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <div lg="12" class="text-right" v-if="userData">
          <button
            type="submit"
            class="btn btn-black"
            :disabled="btnState.btnDisable"
          >
            {{ btnState.btnText }}
          </button>
        </div>
      </CCardFooter>
    </form>
  </CCard>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { formSharedProps } from "@coreui/vue";
import SltGarbage from "./SltGarbage";
export default {
  components: {
    Datepicker,
    vSelect,
    SltGarbage,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      darkModal: false,
      qrString: null,
      generateResult: null,
      depositer: null,
      depositerDetail: "",
      weight: null,
      btnState: { btnText: "ดำเนินการถอน", btnDisable: false },
      sumDeposit: 0,
      sumWithdraw: 0,
      withdrawVal: 0,
    };
  },
  mounted() {},
  computed: {
    total() {
      if (this.$store.state.selectedGarbage) {
        return (
          parseFloat(this.$store.state.selectedGarbage.garbage_price_val) *
          parseFloat(this.weight)
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    createWithdraw(event) {
      if (!this.depositerDetail) {
        alert("โปรดระบุเลขประจำตัวของผู้ฝาก");
        return false;
      }

      let text;
      if (
        confirm(
          "=== ตรวจสอบข้อมูลการถอน===\nชื่อผู้ถอน: " +
            this.depositerDetail.std_name +
            "  " +
            this.depositerDetail.std_surname +
            "\n" +
            "ยอดที่ถอน : " +
            this.withdrawVal.toLocaleString() +
            " บาท"
        ) == true
      ) {
        //CONSTRUCT REQUEST FOR LITTLE BANK
        this.btnState = { btnText: "กำลังบันทึก", btnDisable: true };
        const form = document.getElementById(event.target.id);
        const formData = new FormData(form);
        formData.append("txtAction", "createDeposit");
        formData.append("depositId", 1);
        formData.append("productId", 6);
        formData.append(
          "price",
          -1
        );
        formData.append(
          "quantity",
          this.withdrawVal
        );
        formData.append("userId", this.userData.stud_id);
        let requestLittleBank = this.axios.post(
          this.$hostUrl + "php_action/depositAPI.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );


        //RUN ALL REQUEST
        this.axios
          .all([requestLittleBank])
          .then(
            this.axios.spread((...responses) => {
              const responseLittleBank = responses[0];
              
              if (responseLittleBank.data.createState == "created") {
                alert("บันทึกการถอนสำเร็จ");
                location.reload();
              } else {
                console.log(responseLittleBank.error);
                this.btnState = { btnText: "บันทึก", btnDisable: false };
              }
            })
          )
          .catch((errors) => {
            alert(errors);
          });
      } else {
        return false;
      }
    },
    charIsLetter(char) {
      if (typeof char !== "string") {
        return false;
      }

      return char.toLowerCase() !== char.toUpperCase();
    },

    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    checkIsFilled() {
      if (this.depositer.length >= 5) {
        let formData = new FormData();
        formData.append("txtAction", "getStdData");
        formData.append("stdId", this.depositer);
        formData.append("schoolCode", this.userData.std_school);

        const promise1 = this.axios.post(
          this.$hostUrl + "php_action/userAPI.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        let formData2 = new FormData();
        formData2.append("txtAction", "getDepositLogByDepositor");
        formData2.append("depositorId", this.depositer);
        formData2.append("schoolCode", this.userData.std_school);

        const promise2 = this.axios.post(
          this.$hostUrl + "php_action/depositAPI.php",
          formData2,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        Promise.all([promise1, promise2]).then((values) => {
          //console.log(values[0].data);
          this.depositerDetail = values[0].data.mainData;
          let accountData = values[1].data.mainData;

          let sumDeposit = 0;
          let sumWithdraw = 0;

          if (accountData.length > 0) {
            accountData.forEach((element) => {
              if (element.garbage_price_id == 6) {
                sumWithdraw += parseFloat(element.total);
              } else {
                sumDeposit += parseFloat(element.total);
              }
            });
          }

          this.sumDeposit = sumDeposit;
          this.sumWithdraw = sumWithdraw;
          this.withdrawVal = sumDeposit + sumWithdraw;
        });
      }
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
