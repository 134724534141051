<template>
  <CCard>
    <CCardHeader class="bg-orange text-white"
      >สรุปรายรายฝากขยะ ของโรงเรียน{{ userData.school_name }}
      <div class="text-right"></div>
    </CCardHeader>

    <CCardBody v-if="rows">
      <CRow style="margin-bottom: 20px">
        <CCol lg="6">
          <div class="relative w-full mb-3" v-if="userData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่:
            </label>
            <datepicker
              name="dateStart"
              input-class="form-control bg-white"
              :required="true"
              format="yyyy-MM-dd"
              placeholder="คลิ๊ก"
              v-model="startDate"
            ></datepicker>
          </div>
        </CCol>

        <CCol lg="12" class="text-right" style="margin-bottom: 20px">
          <button type="button" class="btn btn-primary" @click="updateTable">
            search
          </button>
        </CCol>
        <CCol
          lg="12"
          class="text-center"
          style="margin-bottom: 20px"
          v-if="startDate"
        >
          <strong
            >ประจำวันที่:
            {{
              startDate.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </strong>
        </CCol>
        <CCol lg="4" class="text-primary">
          รวมยอดฝาก: {{ sumDeposit.toLocaleString() }} บาท
        </CCol>
        <CCol lg="4" class="text-danger">
          รวมยอดเงินถอน: {{ sumWithdraw.toLocaleString() }} บาท
        </CCol>
        <CCol lg="4">
          คงเหลือ: {{ (sumDeposit + sumWithdraw).toLocaleString() }} บาท
        </CCol>
      </CRow>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{ enabled: true }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'ddetail_id', type: 'desc' },
        }"
        :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
      >
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'garbage_price_name'">
            <CBadge color="danger" v-if="props.row.garbage_price_id == 6">{{
              props.row.garbage_price_name
            }}</CBadge>
            <CBadge color="success" v-else>{{
              props.row.garbage_price_name
            }}</CBadge>
          </span>
          <span v-else-if="props.column.field == 'user_fname'">
            <router-link :to="'/statByTeacher/' + props.row.teacher_id">
              {{ props.row.user_fname }}
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'tc_citeria_name'">
            <router-link :to="'/statByReason/' + props.row.tc_citeria_no">
              {{ props.row.tc_citeria_name }}
            </router-link>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>

          <span v-if="props.column.field == 'actions'">
            <div v-if="props.row.teacher_id == userData.user_id">
              <button
                class="btn btn-warning"
                type="button"
                @click="openModal(props.row.att_id)"
              >
                edit
              </button>
              <button
                class="btn btn-danger"
                @click="removeAtt(props.row.att_id)"
                style="margin-top: 0.2rem"
              >
                delete
              </button>
            </div>
          </span>
        </template>
        <div slot="emptystate">This will show up when there are no rows</div>
      </vue-good-table>
    </CCardBody>
    <CCardFooter class="text-right"> </CCardFooter>

    <!-------MODAL EDIT----------------------------------------->
    <form
      id="formUpdateAbs"
      v-on:submit.prevent="updateData"
      method="POST"
      v-if="selectedAttId"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <!--body-->
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              #
            </label>
            <input
              type="text"
              name="txtAttId"
              class="form-control"
              readonly
              v-model="selectedData.att_id"
            />
          </CCol>
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่
            </label>
            <datepicker
              name="txtDate"
              input-class="form-control"
              :required="true"
              format="yyyy-MM-dd"
              placeholder="วันที่จัดกิจกรรม"
              v-model="selectedData.abs_date"
            ></datepicker>
          </CCol>
          <CCol lg="12">
            <SltCriteria />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไข</h3>
          </h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
          <CButton @click="updateData" color="info">Save</CButton>
        </template>
      </CModal>
    </form>
  </CCard>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import SltCriteria from "./SltCriteria";

export default {
  components: {
    Datepicker,
    vSelect,
    VueGoodTable,
    SltCriteria,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      showModal: false,

      rows: null,
      uploadFiles: [],
      activeTab: 0,
      invoiceStart: null,
      invoiceStop: null,
      startDate: null,
      endDate: null,
      columns: [
        {
          label: "#",
          field: "ddetail_id",
          type: "number",
        },
        {
          label: "วันที่ฝาก",
          field: "ddetail_created",
        },
        {
          label: "เลขประจำตัวผู้นำฝาก",
          field: "ddetail_customer_id",
        },
        {
          label: "ชื่อผู้ทำรายการ",
          field: "customer_name",
        },
        {
          label: "รายการ",
          field: "garbage_price_name",
        },
        {
          label: "ราคา/หน่วย (บาท)",
          field: "ddetail_price",
          type: "number",
        },
        {
          label: "น้ำหนัก (กิโลกรัม)",
          field: "ddetail_quantity",
          type: "number",
        },
        {
          label: "รวม (บาท)",
          field: "total",
          type: "number",
        },

        {
          label: "เจ้าหน้าที่",
          field: "creator_name",
        },
      ],
      selectedAttId: null,
      selectedData: null,
      sumDeposit: 0,
      sumWithdraw: 0,
    };
  },
  mounted() {
    //console.log(this.userData);

    let dNow = new Date();
    this.startDate = dNow;

    let y = dNow.getFullYear();
    let m = dNow.getMonth() + 1;
    m = String(m).padStart(2, "0");
    let d = dNow.getDate();
    d = String(d).padStart(2, "0");
    let dNowStr = y + "-" + m + "-" + d;

    //FETCH COVID BY DATE NOW
    //console.log();
    this.searchDepositByDate(dNowStr);
  },
  methods: {
    searchDepositByDate(dateInp) {
      const formData = new FormData();
      formData.append("txtAction", "getDepositLogByDATE");
      formData.append("startDate", dateInp);
      formData.append("endDate", dateInp);
      if(this.userData.std_school){
        formData.append("schoolCode", this.userData.std_school);
      }
      else{
        formData.append("schoolCode", this.userData.user_address);
      }
      
      this.axios
        .post(this.$hostUrl + "php_action/depositAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.rows = response.data.mainData;
          this.sumDeposit = response.data.sumDeposit;
          this.sumWithdraw = response.data.sumWithdraw;
        });
    },
    updateTable() {
      let dNow = new Date(this.startDate);
      let y = dNow.getFullYear();
      let m = dNow.getMonth() + 1;
      m = String(m).padStart(2, "0");
      let d = dNow.getDate();
      d = String(d).padStart(2, "0");
      let dNowStr = y + "-" + m + "-" + d;
      this.searchDepositByDate(dNowStr);
    },

    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
          this.$store.commit("setSelectedCriteria", {
            code: response.data.mainData.tc_citeria_no,
            label: response.data.mainData.tc_citeria_name,
          });
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.criteriaInfo = response.data.mainData;
        });
    },
    closeModal() {
      //location.reload();
      this.darkModal = false;
    },
    updateData() {
      const form = document.getElementById("formUpdateAbs");
      //console.log(form);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.$store.state.selectedCriteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    handleImages(files) {
      this.uploadFiles = files;
      //console.log(this.uploadFiles);
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("attId", value);
        formData.append("userId", this.userData.user_id);
        //console.log(formData);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == "deleted") {
              alert("บันทึกสำเร็จ");
              location.reload();
            } else {
              alert(response.data.error);
            }
          });
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
