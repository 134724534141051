<template>
  <CCard v-if="userData.std_auth.search('littleBank') > 0">
    {{ $store.state.selectedStudent }}
    <form id="formCreateDeposit" v-on:submit.prevent="createDeposit" method="POST">
      <CCardHeader class="bg-warning"><strong class="text-white">บันทึกการฝากขยะ</strong></CCardHeader>
      <CCardBody>
        <CRow>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสผู้ทำรายการ:
              </label>
              <input type="number" name="userId" class="form-control" aria-label="Username"
                aria-describedby="basic-addon1" readonly v-model="userData.stud_id" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสโรงเรียน:
              </label>
              <input type="number" name="schoolCode" class="form-control" readonly v-model="userData.std_school" />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                รหัสนักเรียนผู้นำฝาก:
              </label>
              <input type="text" name="customerId" class="form-control" v-model="depositer" required
                @input="checkIsFilled" />
            </div>
          </CCol>
          <CCol lg="12" v-if="userData">
            <div v-if="depositerDetail" class="alert alert-info">
              <div>
                <strong>ชื่อ นามสกุล:</strong> {{ depositerDetail.prefix_name
                }}{{ depositerDetail.std_name }}
                {{ depositerDetail.std_surname }}
              </div>
              <div>
                <strong>ห้อง:</strong> {{ depositerDetail.level_abv_name }}/{{
                  depositerDetail.std_class
                }}
              </div>
            </div>
            <div v-else>--โปรดกรอกเลขประจำตัวนักเรียนให้ถูกต้อง--</div>
          </CCol>

          <CCol lg="6" v-if="userData">
            <SltGarbage style="height: 75px" />
            <div v-if="$store.state.selectedGarbage" class="alert alert-warning text-right">
              ราคารับซื้อ:
              {{ $store.state.selectedGarbage.garbage_price_val }} บาท
            </div>
            <div v-else>--โปรดระบุชนิดของขยะ--</div>
          </CCol>
          <CCol lg="6" v-if="userData">
            <div class="relative w-full mb-3" style="height: 75px">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                น้ำหนัก (กิโลกรัม):
              </label>
              <input type="text" name="quantity" class="form-control" v-model="weight" required />
            </div>
            <div v-if="total > 0" class="alert alert-success text-right">
              เงินที่จะได้รับ: {{ total }} บาท
            </div>
            <div v-else>เงินที่จะได้รับ: {{ total }} บาท</div>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <div lg="12" class="text-right" v-if="userData">
          <button type="submit" class="btn btn-warning" :disabled="btnState.btnDisable">
            {{ btnState.btnText }}
          </button>
        </div>
      </CCardFooter>
    </form>
  </CCard>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { formSharedProps } from "@coreui/vue";
import SltGarbage from "./SltGarbage";
export default {
  components: {
    Datepicker,
    vSelect,
    SltGarbage,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      darkModal: false,
      qrString: null,
      generateResult: null,
      depositer: null,
      depositerDetail: "",
      weight: null,
      btnState: { btnText: "บันทึก", btnDisable: false },
    };
  },
  mounted() { },
  computed: {
    total() {
      if (this.$store.state.selectedGarbage) {
        return (
          parseFloat(this.$store.state.selectedGarbage.garbage_price_val) *
          parseFloat(this.weight)
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    createDeposit(event) {
      if (this.$store.state.selectedGarbage.length == 0) {
        alert("โปรดระบุชนิดของขยะ");
        return false;
      } else if (this.charIsLetter(this.weight)) {
        alert("โปรดระบุน้ำหนักเป็นตัวเลขทศนิยมเท่านั้น");
        return false;
      }

      let text;
      if (
        confirm(
          "=== ตรวจสอบข้อมูลการฝาก===\nชื่อผู้ฝาก: " +
          this.depositerDetail.std_name +
          "  " +
          this.depositerDetail.std_surname +
          "\n" +
          "ขยะที่นำฝาก: " +
          this.$store.state.selectedGarbage.label +
          "\n" +
          "น้ำหนัก : " +
          this.weight +
          " กิโลกรัม" +
          "\n" +
          "ยอดเงินที่ได้รับ: " +
          this.total +
          " บาท"
        ) == true
      ) {
        //CONSTRUCT REQUEST FOR LITTLE BANK
        this.btnState = { btnText: "กำลังบันทึก", btnDisable: true };
        const form = document.getElementById(event.target.id);
        const formData = new FormData(form);
        formData.append("txtAction", "createDeposit");
        formData.append("depositId", 0);
        formData.append("productId", this.$store.state.selectedGarbage.code);
        formData.append(
          "price",
          this.$store.state.selectedGarbage.garbage_price_val
        );
        formData.append("userId", this.userData.stud_id);
        formData.append(
          "selectedSemester",
          this.$store.state.selectedSemester.code
        );
        if (this.$store.state.selectedGarbage.code == 5) {
          //MICHELANUS
          formData.append("txtReason", 284);
        }
        else if ((this.$store.state.selectedGarbage.code == 3 || this.$store.state.selectedGarbage.code == 4)) {
          formData.append("txtReason", 285);
        }

        let dNow = new Date();
        let y = dNow.getFullYear();
        let m = dNow.getMonth() + 1;
        m = String(m).padStart(2, "0");
        let d = dNow.getDate();
        d = String(d).padStart(2, "0");
        let dNowStr = y + "-" + m + "-" + d;
        formData.append("txtDate", dNowStr);


        let requestLittleBank = this.axios.post(
          this.$hostUrl + "php_action/depositAPI.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        //CONSTRUCT REQUEST FOR BEH SCORE
        /*
        let formBeh = new FormData();
        formBeh.append("txtAction", "create");
        formBeh.append(
          "selectedSemester",
          this.$store.state.selectedSemester.code
        );
        formBeh.append("selectedSubject", 287);
        formBeh.append(
          "selectedStudent",
          JSON.stringify([{ code: this.depositerDetail.stud_id }])
        );
        formBeh.append("sltHours", 0);
        if (this.$store.state.selectedGarbage.code == 5) {
          formBeh.append("txtReason", 284);
        }
        else if ((this.$store.state.selectedGarbage.code == 3 || this.$store.state.selectedGarbage.code == 4) && this.weight <= 1) {
          formBeh.append("txtReason", 285);
        }
        else if ((this.$store.state.selectedGarbage.code == 3 || this.$store.state.selectedGarbage.code == 4) && this.weight <= 2) {
          formBeh.append("txtReason", 287);
        }

        formBeh.append("txtSchoolId", this.userData.std_school);

        let dNow = new Date();
        let y = dNow.getFullYear();
        let m = dNow.getMonth() + 1;
        m = String(m).padStart(2, "0");
        let d = dNow.getDate();
        d = String(d).padStart(2, "0");
        let dNowStr = y + "-" + m + "-" + d;
        formBeh.append("txtDate", dNowStr);
        formBeh.append("userId", 337);

        let requestBeh = this.axios.post(
          this.$hostUrl + "php_action/attendanceAPI.php",
          formBeh,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        */

        //RUN ALL REQUEST
        this.axios
          .all([requestLittleBank])
          .then(
            this.axios.spread((...responses) => {
              const responseLittleBank = responses[0];
              //const responseBeh = responses[1];
              if (responseLittleBank.data.createState == "created") {
                alert("บันทึกการฝากขยะสำเร็จ");
                location.reload();
 
              } else {
                console.log(responseLittleBank.error);
                this.btnState = { btnText: "บันทึก", btnDisable: false };
              }
              /*
              if (responseBeh.data.createState == "created") {
                alert("บันทึกคะแนนพฤติกรรมสำเร็จ");
                //this.$router.push({ name: "หน้าหลักงานธนาคารขยะ" });
                location.reload();
              
              } else {
                console.log(responseLittleBank.error);
                this.btnState = { btnText: "บันทึก", btnDisable: false };
              }
              */
            })
          )
          .catch((errors) => {
            alert(errors);
          });
      } else {
        return false;
      }
    },

    charIsLetter(char) {
      if (typeof char !== "string") {
        return false;
      }

      return char.toLowerCase() !== char.toUpperCase();
    },

    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    checkIsFilled() {
      if (this.depositer.length >= 5) {
        let formData = new FormData();
        formData.append("txtAction", "getStdData");
        formData.append("stdId", this.depositer);
        formData.append("schoolCode", this.userData.std_school);

        this.axios
          .post(this.$hostUrl + "php_action/userAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            //console.log(response.data);
            this.depositerDetail = response.data.mainData;
          });
      }
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
